import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/about.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPlus,
  faAngleRight,
  faAngleLeft
} from '@fortawesome/free-solid-svg-icons';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import ReactHtmlParser from 'react-html-parser';
import Swiper from 'react-id-swiper/lib';

import { breakpoints, colors, fonts } from '../style-utilities/variables';
import PagesHero from '../components/PagesHero';
import StyledLink from '../components/StyledLink';
import placeHolderImage from '../images/placeholder.png';
import AccordionComponent from '../components/AccordionComponent';
import ProductCategoryCarousel from '../components/ProductCategoryCarousel';
import ProductCarousel from '../components/ProductCarousel';
import HtmlParser from 'react-html-parser/lib/HtmlParser';

const ProductCard = styled.div`
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 5%;
  max-width: 500px;

  .image-card {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-card img {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 800px) {
    width: 200px;
  }
  @media (min-width: 960px) {
    margin: 30px 20px 0;
    .image-card {
      height: auto;
    }
  }
`;

const Div = styled.div`
  .hero-section {
    background: ${props => `url(${props.bgImage}) no-repeat`};

    background-size: cover;
    @media (min-width: 768px) {
      padding-left: 5%;
      height: 600px;
    }
    @media (min-width: 1460px) {
      height: calc(100vh - 175px);
    }

    .opacity {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }
  hr {
    width: 30%;
    margin: 40px auto 40px;
  }
  h2 {
    margin-top: 0;
  }
  p {
    margin: 10px;
    @media (min-width: ${breakpoints.tablet}) {
      margin: 10px 0;
    }
  }

  .OurStory {
    .timelineIntro {
      padding: 20px 15px;
      a {
        color: #be2c31;
        :hover {
          color: red;
        }
      }
      @media (min-width: ${breakpoints.desktop}) {
        margin: 40px;
      }
    }
  }
  .faqs {
    width: 80%;
    margin: 0 auto 80px;
    .about-title {
      margin-bottom: 35px;
    }
    .faq-button {
      text-align: center;
      margin-top: 20px;
    }
  }
  .Carousel {
    padding: 0 40px;
  }
  .related-products {
    text-align: center;
    margin-top: 20px;
    .selected-products {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      a {
        text-decoration: none;
      }
    }
    .product-text {
      font-size: 16px;
      margin-bottom: 22px;
    }

    .related-prod-link {
      font-weight: bold;
      font-size: 16px;
      padding: 12px 0 6px 0;
      display: block;
      /* min-height: 174px; */
      height: auto;
      margin: 0 auto;
    }
    .desktop-products {
      display: none;
    }
    .mobile-product-slider {
      .product-card {
        margin: 0 auto;
      }
      a {
        text-decoration: none;
      }
    }
    @media (min-width: 800px) {
      .desktop-products {
        display: block;
      }
      .related-prod-link {
        /* height: 234px; */
        height: auto;
      }
      .mobile-product-slider {
        display: none;
      }
    }
  }

  .related-footer {
    text-align: center;
    margin-top: 25px;
  }
  .brand-statement {
    margin: 60px auto;
  }
  @media (max-width: ${breakpoints.tablet}) {
    .faqs {
      width: 100%;
    }
    .Carousel {
      padding: 0;
    }
    .related-footer {
      margin-top: 10px;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const Section = styled.div`
  display: block;
  margin: 1em 0;
  margin-bottom: 1.5em;
  @media (min-width: ${breakpoints.tablet}) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    margin: 40px;
    &:nth-child(even) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }
  }

  .story {
    display: block;
    align-content: center;
    padding: 0 15px;
    .section-headline {
      font-size: 28px;
      @media (min-width: 768px) {
        font-size: 36px;
      }
    }
  }

  .imgWrapper {
    background: silver no-repeat;
    background-size: cover;
    min-height: 300px;
  }
  img {
    max-width: 100%;
    @media (min-width: ${breakpoints.tablet}) {
      width: 40%;
    }
    @media (min-width: ${breakpoints.desktop}) {
      width: 45%;
      flex-shrink: 0;
    }
  }
`;

class About extends Component {
  state = {
    activeAcc: 0
  };
  // All FAQs from CMS
  allFaqs = this.props.data.allWordpressWpFaq.edges;
  // All Products from CMS
  allProducts = this.props.data.allWordpressWpProducts.edges;
  //Product Category from CMS
  productLine = this.props.data.allWordpressWpProductLine.edges;
  //Getting Slug for Product Category
  productLineSlugs = this.productLine.map(el => el.node.slug);
  // Featured FAQs from CMS
  featuredFaqs = this.props.data.wordpressPage.acf.featured_faqs;
  // Featured Products from CMS
  featuredProducts = this.props.data.wordpressPage.acf.featured_products;

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.triggerUpdate);
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `${process.env.WP_BASE_URI}/${
        process.env.ACTIVE_BRAND
      }/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  triggerUpdate = () => {
    this.forceUpdate();
  };

  accordionChangeHandler = clickedAcc => {
    this.setState({ activeAcc: clickedAcc });
  };

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.triggerUpdate);
    }
  }

  render() {
    let about_page;

    if (this.state.isPreviewMode) {
      about_page = this.state.previewPayload;
    } else {
      about_page = this.props.data.wordpressPage;
    }
    const {
      data: {
        wordpressPage: {
          acf: { product_category }
        }
      }
    } = this.props;
    //Hero Image for About Page
    const heroImage =
      this.props.data.wordpressPage.acf.hero_image === null
        ? placeHolderImage
        : this.props.data.wordpressPage.acf.hero_image.source_url;
    /**
     * ------------------FEATURED FAQs------------------------------
     */
    //Get Properties for Featured FAQs by filtering all FAQs
    const selectedFaqs = this.allFaqs.filter(el =>
      this.featuredFaqs.some(item => item.post_name === el.node.slug)
    );
    //Add ID property to FeaturedFaqs
    selectedFaqs.forEach((el, index) => (el.node.id = index + 1));
    /**
     * --------------------------------------------------------------
     */
    /**
     * ---------------------FEATURED PRODUCTS------------------------
     */
    //Get properties for Featured Products by filtering all products
    const selectedProducts = this.allProducts.filter(el =>
      this.featuredProducts.some(item => item.post_name === el.node.slug)
    );
    const selectedProductSlugs = selectedProducts.map(el => el.node.slug);
    /**
     * --------------------------------------------------------------
     */
    /**
     * -----------------------Product Content------------------------
     */
    const productContent = selectedProducts.map(
      ({ node: { slug, title, acf } }) => {
        return (
          <Link key={slug} to={`/products/${slug}`}>
            <ProductCard className="product-card">
              <div className="image-card">
                {acf.related_thumbnail !== null ? (
                  <img
                    src={acf.related_thumbnail.source_url}
                    alt="placeHolderImage"
                  />
                ) : (
                  <img src={placeHolderImage} alt="placeHolderImage" />
                )}
              </div>
              <div className="related-prod-link">
                <h3>{ReactHtmlParser(title)}</h3>
                <p className="product-text">
                  <span id="content-info">
                    {ReactHtmlParser(acf.short_description)}
                  </span>
                </p>
              </div>
            </ProductCard>
          </Link>
        );
      }
    );
    /**
     * --------------------------------------------------------------
     */
    /**
     * -----------------------Product CategoryContent------------------------
     */
    const productCategoryContent = this.productLine.map(
      ({ node: { slug, name, description, acf } }) => {
        return (
          <Link key={slug} to={`/product-categories/${slug}`}>
            <ProductCard className="product-card">
              <div className="image-card">
                {acf.menu_image !== null ? (
                  <img src={acf.menu_image.source_url} alt="placeHolderImage" />
                ) : (
                  <img src={placeHolderImage} alt="placeHolderImage" />
                )}
              </div>
              <div className="related-prod-link">
                <h3>{ReactHtmlParser(name)}</h3>
                <p className="product-text">
                  <span id="content-info">
                    {ReactHtmlParser(acf.sub_headline)}
                  </span>
                </p>
              </div>
            </ProductCard>
          </Link>
        );
      }
    );
    /**
     * --------------------------------------------------------------
     */
    // Mobile Setting for Carousel
    const settings = {
      loop: true,
      speed: 500,
      slidesToShow: 1,
      type: 'bullets',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    };
    return (
      <Div bgImage={heroImage}>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <PagesHero className="hero-section">
          {this.props.siteTitle === 'mahatma' ? (
            <div className="smokeBlur" />
          ) : (
            ''
          )}

          <div className="InnerBox">
            <div className="title">
              <h1>{ReactHtmlParser(about_page.acf.headline)}</h1>
              <h2>{ReactHtmlParser(about_page.acf.sub_headline)}</h2>
              {ReactHtmlParser(about_page.acf.overview_section)}
            </div>
          </div>
        </PagesHero>

        <div className="OurStory">
          <div className="timelineIntro">
            {HtmlParser(about_page.acf.intro_copy)}
          </div>
          {about_page.acf.content_blocks.map((section, index) => {
            return section.image ? (
              <Section key={section.headline} className="about-section">
                <img
                  className=""
                  src={
                    this.state.isPreviewMode
                      ? section.image.url
                      : section.image
                      ? section.image.source_url
                      : placeHolderImage
                  }
                  alt={section.image.alt_text}
                />
                <div className="story">
                  <p className="section-headline">
                    {ReactHtmlParser(section.headline)}
                  </p>
                  {ReactHtmlParser(section.description)}
                </div>
              </Section>
            ) : null;
          })}
        </div>

        <div className="faqs">
          <div className="small-img" />
          {this.props.siteTitle === 'mahatma' ? (
            <div className="chalk-heading">
              <p id="faqs-title" className="about-title">
                {ReactHtmlParser(about_page.acf.faqs_title)}
              </p>
              <div className="chalk-underline" />
            </div>
          ) : (
            <p id="faqs-title" className="about-title">
              {ReactHtmlParser(about_page.acf.faqs_title)}
            </p>
          )}

          {selectedFaqs.map(faq => {
            return (
              <AccordionComponent
                key={faq.node.id}
                clicked={() => this.accordionChangeHandler(faq.node.id)}
                symbol={
                  this.state.activeAcc === faq.node.id ? (
                    <FontAwesomeIcon icon={faMinus} />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )
                }
                accState={this.state.activeAcc === faq.node.id}
                id={faq.node.id}
                title={ReactHtmlParser(faq.node.title)}
                content={ReactHtmlParser(faq.node.content)}
              />
            );
          })}
          <div className="faq-button">
            <StyledLink link="/faqs">See All FAQs</StyledLink>
          </div>
        </div>
        <div className="related-products">
          <div className="small-img" />
          {this.props.siteTitle === 'mahatma' ? (
            <div className="chalk-heading">
              <p className="about-title">
                {ReactHtmlParser(about_page.acf.featured_products_title)}
              </p>
              <div className="chalk-underline" />
            </div>
          ) : (
            <p className="about-title">
              {ReactHtmlParser(about_page.acf.featured_products_title)}
            </p>
          )}

          <p className="about-subHeadtitle">
            {ReactHtmlParser(about_page.acf.featured_products_subtitle)}
          </p>

          <div className="desktop-products">
            {!product_category && productContent.length <= 4 ? (
              <div className="selected-products">{productContent}</div>
            ) : !product_category && productContent.length > 4 ? (
              <ProductCarousel carouselItems={selectedProductSlugs} />
            ) : product_category && productCategoryContent.length <= 4 ? (
              <div className="selected-products">{productCategoryContent}</div>
            ) : (
              <ProductCategoryCarousel
                carouselItems={this.productLineSlugs}
                categoryEnable={product_category}
              />
            )}
          </div>
          <div className="mobile-product-slider">
            {!product_category ? (
              <Swiper {...settings}>{productContent}</Swiper>
            ) : (
              <Swiper {...settings}>{productCategoryContent}</Swiper>
            )}
          </div>
        </div>
        <div className="related-footer">
          <StyledLink link="/products">See All Products</StyledLink>
        </div>
      </Div>
    );
  }
}

export default About;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "about" }) {
      slug
      content
      title
      yoast_head_json {
        title
        description
      }
      acf {
        featured_products_title
        featured_products_subtitle
        featured_products {
          post_name
        }
        product_category
        faqs_title
        sub_headline
        overview_section
        brand_statement
        hero_image {
          source_url
        }
        featured_faqs {
          post_name
        }
        hero_content
        headline
        intro_copy
        content_blocks {
          headline
          description
          image {
            source_url
            alt_text
          }
        }
      }
    }
    allWordpressWpFaq {
      edges {
        node {
          slug
          title
          content
        }
      }
    }
    allWordpressWpProductLine {
      edges {
        node {
          name
          slug
          description
          acf {
            sub_headline
            menu_image {
              source_url
            }
          }
        }
      }
    }
    allWordpressWpProducts {
      edges {
        node {
          slug
          acf {
            product_image {
              source_url
            }
            related_thumbnail {
              source_url
            }
            short_description
          }
          title
          content
        }
      }
    }
  }
`;
