import { css } from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  rosebud
} from '../../style-utilities/variables';
import minus from '../../images/minus-grey.svg';
import plus from '../../images/plus-grey.svg';
import rose from '../../images/rose_75x73.png';
export default css`
  .hero-section {
    position: relative;
    .Hero {
      z-index: 1;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      h1,
      h3 {
        padding: 0;
        margin: 0;
      }
      h1 {
        color: ${colors.primary};
        order: 1;
        //font-family: sans-serif;
        font-size: 46px;
        margin: 10px;
      }
      h3 {
        //font-size: 20px;
        //order: 2;
        display: none;
      }
      p {
        margin: 0;
        order: 2;
        font-size: 25px;
        padding: 0 20px;
      }
    }
    :after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top:0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.2);
    }
    
    .opacity{
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      z-index: 2;
      .title{
        text-align:center;
        p{
          display:none;
        }
      }
    }
    @media (min-width: ${breakpoints.tablet}) {
      height:0;
      min-height: 550px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      background-position: 0 -350px;
    }
  }
  .story {
    .section-headline{
      font-size: 30px;
      font-family:${fonts.primary};
      color: ${colors.primary};
      font-weight: bold;
      margin-bottom: 18px;
      @media(min-width:${breakpoints.generalDesktop}){
        font-size: 36px;
      }
    }
  }
  .OurStory {
    .timelineIntro{
      display:none;
    }
    .story {
    .section-headline{
      font-size: 30px;
      font-family:${fonts.primary};
      color: ${colors.primary};
      font-weight: bold;
      margin-bottom: 18px;
      line-height: 32px;
      @media(min-width:${breakpoints.generalDesktop}){
        font-size: 36px;
      }
    }
    p{
line-height: 24px;
    }
  }
    @media (min-width: ${breakpoints.generalDesktop}) {
      div[class*='about__Section'] {
        margin: 40px 80px 0;
        align-items:center;
        .story {
          display: inline-block;
          margin: 20px 40px 0 0;
          .section-headline{
            font-size: 36px;
          }
        }
        h2,
        p {
          text-align: left;
        }
        h2 {
          font-size: 31px;
          padding: 0 0 10px 0;
        }
        p {
          margin: 0 95px 18px 0;
        }
        .story:nth-child(even) {
          margin: 20px 0 0 50px;
        }
      }
    }
  }
  
  div[class*='AccordionComponent__'] {
    margin-bottom: 20px;
  }
  .accordion__item {
    outline: 0;
    position: relative;
    .accordion__arrow {
      display: none;
    }
    .accordion__title,
    .accordion__body {
      padding: 20px;
      position: relative;
    }
    .accordion__title {
      outline: none;
    }
    /*.accordion__title::after {
      content: '';
      background: url(${minus}) no-repeat center;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 45%;
      right: 5%;
      transform: translateY(-50%);
    }
    .hideBodyTitle::after {
      background: url(${plus});
    }*/
  }
  .featured-products {
    div[class*='about__ProductCard'] {
      min-height: 375px;
      position: relative;
      .product-info {
        position: absolute;
        p {
          color: ${colors.bodyText};
        }
        h3 {
          color: ${colors.primary};
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .brand-statement {
    margin-bottom: 60px;
  }
  .small-img {
    background-image: url(${rose});
    height: ${rosebud.height};
    width: ${rosebud.width};
    background-repeat: no-repeat;
    background-size: cover;
    margin: 1em auto;
    @media(min-width:${breakpoints.tablet}){
       margin-top:2em;
    }
  }
  .faqs {
    margin-bottom: 2em;
  }
  #faqs-title{
    color: ${colors.primary};
    font-family: ${fonts.primary}, 'sans-serif';
    font-weight: 600;
    font-size: 30px;
    text-align:center;
    @media(min-width:${breakpoints.generalDesktop}){
      font-size: 36px;
      color: ${colors.primary};
    font-family: ${fonts.primary}, 'sans-serif';
    }

  }
  .selected-products{
   .related-prod-link{
     h3{
       color: ${colors.primary};
       font-size: 18px;
       font-weight: bold;
     }
     .product-text{
       color:${colors.bodyText};
     }
   }
  }
  .related-products{
    .about-title{
      font-family: ${fonts.primary}, 'sans-serif';
      color: ${colors.primary};
      font-size: 30px;
      font-weight: 600;
      @media(min-width:${breakpoints.generalDesktop}){
      font-size: 36px;
    }
    }
    .about-subHeadtitle{
      font-size: 24px;
    }
  }
  .desktop-products {
    a button{display: none;}
  }
  .mobile-product-slider{
    .related-prod-link{
      min-height: 115px;
      h3{
        color:${colors.primary};
        margin-top:0;
        font-size: 18px;
      }
      .product-text{
          color:${colors.bodyText};
      }
    }
  }
  .InnerBox{
    z-index: 5;
  }
`;
